import { default as _47vercel_47path0_47pages_47architecte_46vueMeta } from "/vercel/path0/pages/architecte.vue?macro=true";
import { default as _47vercel_47path0_47pages_47devis_46vueMeta } from "/vercel/path0/pages/devis.vue?macro=true";
import { default as _47vercel_47path0_47pages_47faq_46vueMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as _47vercel_47path0_47pages_47home_46vueMeta } from "/vercel/path0/pages/home.vue?macro=true";
import { default as _47vercel_47path0_47pages_47index_46vueMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _47vercel_47path0_47pages_47mentions_45legales_46vueMeta } from "/vercel/path0/pages/mentions-legales.vue?macro=true";
import { default as _47vercel_47path0_47pages_47realisation_47_91id_93_46vueMeta } from "/vercel/path0/pages/realisation/[id].vue?macro=true";
import { default as _47vercel_47path0_47pages_47realisations_46vueMeta } from "/vercel/path0/pages/realisations.vue?macro=true";
import { default as _47vercel_47path0_47pages_47services_46vueMeta } from "/vercel/path0/pages/services.vue?macro=true";
export default [
  {
    name: _47vercel_47path0_47pages_47architecte_46vueMeta?.name ?? "architecte",
    path: _47vercel_47path0_47pages_47architecte_46vueMeta?.path ?? "/architecte",
    file: "/vercel/path0/pages/architecte.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47architecte_46vueMeta,
    alias: _47vercel_47path0_47pages_47architecte_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47architecte_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/architecte.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47devis_46vueMeta?.name ?? "devis",
    path: _47vercel_47path0_47pages_47devis_46vueMeta?.path ?? "/devis",
    file: "/vercel/path0/pages/devis.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47devis_46vueMeta,
    alias: _47vercel_47path0_47pages_47devis_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47devis_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/devis.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47faq_46vueMeta?.name ?? "faq",
    path: _47vercel_47path0_47pages_47faq_46vueMeta?.path ?? "/faq",
    file: "/vercel/path0/pages/faq.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47faq_46vueMeta,
    alias: _47vercel_47path0_47pages_47faq_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47faq_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47home_46vueMeta?.name ?? "home",
    path: _47vercel_47path0_47pages_47home_46vueMeta?.path ?? "/home",
    file: "/vercel/path0/pages/home.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47home_46vueMeta,
    alias: _47vercel_47path0_47pages_47home_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47home_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/home.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47index_46vueMeta?.name ?? "index",
    path: _47vercel_47path0_47pages_47index_46vueMeta?.path ?? "/",
    file: "/vercel/path0/pages/index.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47index_46vueMeta,
    alias: _47vercel_47path0_47pages_47index_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47mentions_45legales_46vueMeta?.name ?? "mentions-legales",
    path: _47vercel_47path0_47pages_47mentions_45legales_46vueMeta?.path ?? "/mentions-legales",
    file: "/vercel/path0/pages/mentions-legales.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47mentions_45legales_46vueMeta,
    alias: _47vercel_47path0_47pages_47mentions_45legales_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47mentions_45legales_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47realisation_47_91id_93_46vueMeta?.name ?? "realisation-id",
    path: _47vercel_47path0_47pages_47realisation_47_91id_93_46vueMeta?.path ?? "/realisation/:id",
    file: "/vercel/path0/pages/realisation/[id].vue",
    children: [],
    meta: _47vercel_47path0_47pages_47realisation_47_91id_93_46vueMeta,
    alias: _47vercel_47path0_47pages_47realisation_47_91id_93_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47realisation_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/realisation/[id].vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47realisations_46vueMeta?.name ?? "realisations",
    path: _47vercel_47path0_47pages_47realisations_46vueMeta?.path ?? "/realisations",
    file: "/vercel/path0/pages/realisations.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47realisations_46vueMeta,
    alias: _47vercel_47path0_47pages_47realisations_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47realisations_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/realisations.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47services_46vueMeta?.name ?? "services",
    path: _47vercel_47path0_47pages_47services_46vueMeta?.path ?? "/services",
    file: "/vercel/path0/pages/services.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47services_46vueMeta,
    alias: _47vercel_47path0_47pages_47services_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47services_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/services.vue").then(m => m.default || m)
  }
]