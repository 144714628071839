<template>

  <NuxtLoadingIndicator color="#F3EDD6" height="4" />
    <NuxtLayout>
      <NuxtPage/>
      <Footer />
    </NuxtLayout>


</template>

<script setup>

//import {useHead} from "nuxt/app";

import Footer from "/components/Footer";


onMounted(() => {
  const noscript = document.createElement('noscript')

  const iframe = document.createElement('iframe')
  iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-PVZ68D4' // L'URL de votre iframe Google Analytics
  iframe.height = '0'
  iframe.width = '0'
  iframe.style.display = 'none'
  iframe.style.visibility = 'hidden'

  noscript.appendChild(iframe)

  document.body.insertBefore(noscript, document.body.firstChild)
})

</script>

<style>

</style>