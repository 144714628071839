// plugins/gtm-noscript.js
export default defineNuxtPlugin(() => {
    if (process.client) {
        const noscript = document.createElement('noscript')
        noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NBZX6PRW"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `
        document.body.appendChild(noscript)
    }
})
