import node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/vercel/path0/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/router.mjs";
import _nuxt_swiper_mjs_wnFZ5tA1pM from "/vercel/path0/.nuxt/swiper.mjs";
import plugins_gtm_js_MVzSxF8h1r from "/vercel/path0/plugins/gtm.js";
import plugins_gtm_noscript_js_0pQPJ6D4y9 from "/vercel/path0/plugins/gtm-noscript.js";
import plugins_axios_ts_QMFgzss1s4 from "/vercel/path0/plugins/axios.ts";
export default [
  node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  _nuxt_swiper_mjs_wnFZ5tA1pM,
  plugins_gtm_js_MVzSxF8h1r,
  plugins_gtm_noscript_js_0pQPJ6D4y9,
  plugins_axios_ts_QMFgzss1s4
]