import 'swiper/css'
import 'swiper/css/a11y'
import 'swiper/css/effect-cards'
import 'swiper/css/effect-creative'
import 'swiper/css/effect-cube'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-flip'
import 'swiper/css/free-mode'
import 'swiper/css/grid'
import 'swiper/css/lazy'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/virtual'
import 'swiper/css/zoom'
import { Swiper, SwiperSlide } from 'swiper/vue'
export default defineNuxtPlugin((nuxtApp) => {
            nuxtApp.vueApp.component('Swiper', Swiper)
            nuxtApp.vueApp.component('SwiperSlide', SwiperSlide)
          })